import React from 'react';
import { Node, Vertex } from '../../models/force-graph';
import { runForceGraph } from '../../services/force-graph.service';
import { BrTooltip } from '../tooltip/tooltip';

import styles from "./analysis-graph.module.scss";
import groupToggled from "../../assets/toggle-lg-nodes.svg";
import singleToggled from "../../assets/toggle-small-nodes.svg";


export interface BrAnalysisGraphProps {
    links: Vertex[];
    nodes: Node[];
    onNodeGroupToggle: (isGroupToggled: boolean) => void
}
export const BrAnalysisGraph = ({
    links,
    nodes,
    onNodeGroupToggle,
}: BrAnalysisGraphProps) => {
    const [isGroupToggled, setIsGroupToggled] = React.useState(false);
    const nodeTooltip = React.useCallback((node: Node) => {
        return <BrTooltip>{node.name}</BrTooltip>;
    }, []);
    const containerRef = React.useRef(null);
    const onToggle = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        onNodeGroupToggle(!isGroupToggled);
        setIsGroupToggled(!isGroupToggled);
    }

    React.useEffect(() => {
        if (containerRef.current) {
            runForceGraph(containerRef.current, {links, nodes});
        }

        return () => {};
    }, []);

    return <>
        <div className={styles.wrapper}>
            <img
                className={styles.toggle}
                onClick={onToggle}
                src={
                    isGroupToggled ? groupToggled : singleToggled
                }
            />
            <div ref={containerRef} className={styles.container} />
        </div>
    </>;
};
