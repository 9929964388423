import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { getCompanies } from "../../api/company/get-companies";
import { CONNECT, SIGN_IN } from "../../constants/routes";
import { CompanyStoreKey, companyStore } from "../../store/company.store";
import { UserStoreKey, userStore } from "../../store/user.store";

export default async function rootLoader({request}: LoaderFunctionArgs) {
    const reqUrl = new URL(request.url);

    if (isSignIn(reqUrl.pathname)) {
        return {};
    }

    if (userStore.getData(UserStoreKey.ID_TOKEN)) {
        try {
            const companyResponse = await getCompanies();

            if (companyResponse.ok) {
                const companyData = await companyResponse.json();

                companyStore.setData(CompanyStoreKey.COMPANY_ID, companyData[0].id);

                return {};
            } else {
                return redirect("/" + SIGN_IN);
            }
        } catch {
            return redirect("/" + SIGN_IN);
        }
    }

    return redirect("/" + SIGN_IN);
}

function isSignIn(path: string): boolean {
    return path === "/" + SIGN_IN;
}