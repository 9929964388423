import { NavigateFunction } from "react-router-dom";
import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";
import { LoginType } from "../../models/login-type";
import signInSuccess from "../../utils/sign-in-success";

export interface LoginBody {
    type: LoginType;
    login: string;
    password: string;
    token: string;
}

export const login = (body: LoginBody, nav: NavigateFunction) => {
    fetch(`${API_BASE}${API_VERSION}/users/sessions`, {
        method: HttpMethod.POST,
        mode: CORS,
        body: JSON.stringify(body),
        headers: HEADERS,
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }

        return new Error(`${response.status}: ${response.statusText}`)
    })
    .then((data) => signInSuccess(data, nav))
    .catch((err) => {
        console.error(err);
        // TODO: HANDLE ERROR
    });
}

/**
 * RESPONSE
 * 200
 * {
  "idToken": "string",
  "refreshToken": "string",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "expiresIn": 0
}

400
{message: "string"}
 */
