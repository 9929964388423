import { Store } from "./store";

export const enum UserStoreKey {
    ID_TOKEN = "brightride-id-token",
    REFRESH_TOKEN = "brightride-refresh-token",
}

class UserStore extends Store<UserStoreKey> {
    getData(key: UserStoreKey) {
        return localStorage.getItem(key) ?? "";
    }

    setData(key: UserStoreKey, value: any) {
        localStorage.setItem(key, value);
    }
}

export const userStore = new UserStore();
