import { useEffect, useRef, useState, InputHTMLAttributes, RefObject } from 'react';

import inputStyles from './input.module.scss';

export interface BrInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    ref?: RefObject<HTMLInputElement> 
}

export const BrInput = (props:
    BrInputProps
) => {
    const inputRef = useRef<HTMLInputElement>(props.ref?.current ?? null);
    const [isLabelShowing, setIsLabelShowing] = useState(true);

    const onFocusIn = (_: any) => {
        if (document.activeElement === inputRef.current) {
            setIsLabelShowing(false);
        } else if (inputRef.current?.value.length) {
            setIsLabelShowing(false);
        } else {
            setIsLabelShowing(true);
        }
    }

    const onBlur = (_: any) => {
        if (inputRef.current?.value.length === 0) {
            setIsLabelShowing(true);
        }
    }

    useEffect(() => {
        document.addEventListener("focusin", onFocusIn);
        if (inputRef.current?.value.length) {
            setIsLabelShowing(false);
        }
        return () => document.addEventListener("focusin", onFocusIn);
    }, []);

    return <div className={inputStyles.container}>
        {
            isLabelShowing &&
            <label htmlFor={props.name}>{props.label} {props.required && props.label && <span>*</span>}</label>}
            <input {...props} ref={inputRef} onBlur={onBlur} />
    </div>;
}