import { refresh } from "../api/auth/refresh";
import { UserStoreKey, userStore } from "../store/user.store";
import { runOnce } from "../utils/run-once";

class HttpService {
    fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
        return new Promise((resolve, reject) => {
            fetch(input, init)
                .then(resolver(resolve))
                .catch((err) => {
                    if (canRetry(err)) {
                        const refreshToken = userStore.getData(UserStoreKey.REFRESH_TOKEN)

                        if (refreshToken) {
                            refresh(refreshToken)
                                .then(async (response) => {
                                    if (response.ok) {
                                        const data = await response.json();
                                        
                                        userStore.setData(UserStoreKey.ID_TOKEN, data.idToken);
                                        userStore.setData(UserStoreKey.REFRESH_TOKEN, data.refreshToken);

                                        runOnce(() => {
                                            fetch(input, init)
                                                .then(resolver(resolve))
                                                .catch((error) => reject(error))
                                        });
                                    } else {
                                        throw new Error(response.statusText);
                                    }
                                })
                                .catch((refreshError) => {
                                    reject(refreshError)
                                });
                        }
                    } else {
                        reject(err);
                    }
                });
        });
    }
}

function canRetry(error: Error) {
    return (
        error.message === "401"
    );
}

function resolver(resolve: (value: Response | PromiseLike<Response>) => void) {
    return (res: Response) => {
        if (res.ok) {
            resolve(res);
        } else {
            throw new Error(String(res.status));
        }
    };
}

export const httpService = new HttpService();
