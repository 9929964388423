import { NavigateFunction } from "react-router-dom";
import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";
import signInSuccess from "../../utils/sign-in-success";

export interface SignupBody {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string,
    companyName: string,
    password: string,
}

export const signup = (body: SignupBody, nav: NavigateFunction) => {
    fetch(`${API_BASE}${API_VERSION}/users/signup`, {
        body: JSON.stringify(body),
        headers: HEADERS,
        method: HttpMethod.POST,
        mode: CORS,
    }).then((response) => {
      if (response.ok) {
          return response.json();
      }

      return new Error(`${response.status}: ${response.statusText}`)
    })
    .then((data) => signInSuccess(data, nav))
    .catch((err) => {
        console.error(err);
        //TODO: HANDLE ERROR
    });
}

/**
 * Response
 * 200
Successfully signed up

Example Value
Model
{
  "idToken": "string",
  "refreshToken": "string",
  "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "expiresIn": 0
}
400	
Invalid request data
 */
