import { Dispatch, SetStateAction } from "react";
import { uploadCompanyDocument } from "../../../api/company-document/upload-company-document";
import { deleteConnection } from "../../../api/connections/delete-connection";
import { BrButton } from "../../../components/button/button";
import { BrInput } from "../../../components/input/input";
import { ConnectionId, IntegrationId } from "../../../models/connection";
import { DataSource } from "../../../models/data-source";
import { companyStore, CompanyStoreKey } from "../../../store/company.store";
import { FILE_TITLE, UPCOMING_SOURCES_TITLE } from "../data-sources";

import style from "./style.module.scss";

const UPLOAD_ID = "csv-org-upload"; 

interface SourceButtonProps {
    cachedConnections: Map<IntegrationId, ConnectionId[]>
    categoryTitle: string
    createConnection: (id: IntegrationId, d: DataSource["windowDimensions"]) => void
    integrationId: IntegrationId
    setCachedConnections: Dispatch<SetStateAction<Map<IntegrationId, ConnectionId[]>>>
    title: string
    windowDimensions: DataSource["windowDimensions"]
}

export const SourceButton = ({
    cachedConnections,
    categoryTitle,
    createConnection,
    integrationId,
    setCachedConnections,
    title,
    windowDimensions,
}: SourceButtonProps) => {
    if (categoryTitle === UPCOMING_SOURCES_TITLE) {
        return <BrButton disabled style={{whiteSpace: "nowrap"}}>Coming soon</BrButton>;
    }

    if (title === FILE_TITLE) {
        return (
            <>
                <BrInput accept=".csv" type="file" id={UPLOAD_ID} className={style.upload} onChange={(e) => {
                    const file = e.currentTarget.files?.[0];

                    if (file) {
                        uploadCompanyDocument(companyStore.getData(CompanyStoreKey.COMPANY_ID), file);
                    }
                }} />
                <BrButton onClick={() => {
                    document.querySelector<HTMLInputElement>(`#${UPLOAD_ID}`)?.click()
                }} className={style.fileBtn}>Upload</BrButton>
            </>
        );
    }

    if (cachedConnections.has(integrationId)) {
        return (
            <BrButton onClick={() => destroyConnection(
                integrationId,
                cachedConnections.get(integrationId)?.[0] ?? "",
                cachedConnections,
                setCachedConnections,
            )}>Disconnect</BrButton>
        );
    }

    return <BrButton onClick={() => createConnection(integrationId, windowDimensions)}>Connect</BrButton>
}

async function destroyConnection(
    integrationId: IntegrationId,
    connectionId: ConnectionId,
    cachedConnections: Map<IntegrationId, ConnectionId[]>,
    setCachedConnections: Dispatch<SetStateAction<Map<IntegrationId, ConnectionId[]>>>
) {
    const response = await deleteConnection(connectionId);

    if (response.ok) {
        const connections = cachedConnections.get(integrationId)?.filter((cId) => cId !== connectionId) ?? [];

        if (connections.length === 0) {
            cachedConnections.delete(integrationId);

            setCachedConnections(new Map(cachedConnections));
        }
    } else {
        // TODO: Error Handling
    }
}
