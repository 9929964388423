import React, { MouseEvent, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BrInput } from "../../../../components/input/input";

import styles from "../../auth-forms.module.scss";
import { signup } from "../../../../api/auth/signup";
import extractValuesFromForm from "../../../../utils/extract-values-from-form";
import { FormFooter } from "../form-footer/form-footer";
import { VisibleForm } from "../../auth-forms";

export const BrRegister = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  return (
    <div className={styles.formContainer}>
      <form ref={formRef}>
        <BrInput label="Your Email" required type="email" name="email" />
        <div className={styles.divider} />
        <BrInput label="Your First Name" required name="firstName" />
        <div className={styles.divider} />
        <BrInput label="Your Last Name" required name="lastName" />
        <div className={styles.divider} />
        <BrInput label="Your Company Name" required name="companyName" />
        <div className={styles.divider} />
        <BrInput label="Password" required type="password" name="password" />
        <div className={styles.divider} />
        <BrInput label="Password, one more time" required type="password" />

        <div className={styles.agree}>
          <p>
            By signing up you agree to our &nbsp;
            <a
              href="https://app.termly.io/document/terms-of-use-for-saas/ea869917-2881-475a-b584-a02dbfeb9f84"
              className={styles.formLink}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </p>
        </div>
        <FormFooter submit={formSubmit} visibleForm={VisibleForm.SIGN_UP} />
      </form>
    </div>
  );

  function formSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    signup(extractValuesFromForm(formRef.current!), navigate);
  }
};
