import { Store } from "./store"

export const enum CompanyStoreKey {
    COMPANY_ID = "brightride-company-id"
}

class CompanyStore extends Store<CompanyStoreKey> {
    getData(key: CompanyStoreKey) {
        return localStorage.getItem(key) ?? "";
    }

    setData(key: CompanyStoreKey, value: any) {
        localStorage.setItem(key, value);
    }
}

export const companyStore = new CompanyStore();
