import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";
import { userStore, UserStoreKey } from "../../store/user.store";

export const confirmCreateConnection = (query: string) => {
    HEADERS.set("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));

    return fetch(`${API_BASE}${API_VERSION}/connections/callback${query}`, {
        headers: HEADERS,
        method: HttpMethod.POST,
        mode: CORS,
    });
}
