import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import buttonStyles from "./button.module.scss";


export const BrButton = ({
    className,
    ...props
}: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
) =>
    <button className={`${buttonStyles.btn} ${className}`} {...props}>{props.children}</button>;
