import React, {ReactNode} from "react";

import styles from "./tooltip.module.scss";


export interface BrTooltipProps {
    children: ReactNode | ReactNode[]
}

export const BrTooltip = ({children}: BrTooltipProps) => {
    return <>
        <div className={styles.container}>
            {children}
        </div>
    </>;
}
