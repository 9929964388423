import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";
import { UserStoreKey, userStore } from "../../store/user.store";

export const resetPassword = ({ email }: {email: string}) => {
    HEADERS.set("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));

    return fetch(`${API_BASE}${API_VERSION}/users/credentials?email=${encodeURIComponent(email)}`, {
        method: HttpMethod.DELETE,
        mode: CORS,
        headers: HEADERS,
    });
};
