import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";

export const refresh = (refreshToken: string) => {
    return fetch(`${API_BASE}${API_VERSION}/users/sessions`, {
        body: JSON.stringify({token: refreshToken, type: "refresh-token"}),
        headers: HEADERS,
        method: HttpMethod.POST,
        mode: CORS,
    });
};
