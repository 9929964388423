import { MouseEvent, useRef } from "react";
import styles from "../../auth-forms.module.scss";
import { FormFooter } from "../form-footer/form-footer";
import { VisibleForm } from "../../auth-forms";
import { BrInput } from "../../../../components/input/input";
import extractValuesFromForm from "../../../../utils/extract-values-from-form";
import { resetPassword } from "../../../../api/auth/reset-password";

export const Forgot = () => {
  const formRef = useRef(null);

  return (
    <div className={styles.formContainer}>
      <form ref={formRef}>
        <BrInput label="Email" type="email" name="email" />
        <FormFooter submit={formSubmit} visibleForm={VisibleForm.FORGOT} />
      </form>
    </div>
  );

  function formSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    resetPassword(extractValuesFromForm(formRef.current!));
  }
};
