import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { httpService } from "../../services/http.service";
import { UserStoreKey, userStore } from "../../store/user.store";

export const getCompanies = () => {
    HEADERS.set("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));

    return httpService.fetch(`${API_BASE}${API_VERSION}/companies`, {
        headers: HEADERS,
        mode: CORS,
    });
}

// Code	Description
// 200	
// Successful operation

// Example Value
// Model
// [
//   {
//     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "name": "string"
//   }
// ]
// 400	
// Invalid request data
