import styles from "./footer.module.scss";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.png";

export const BrFooter = () => (
  <footer className={styles.footer}>
    <div className={styles.logos}>
      <a
        href="https://twitter.com/brightrideinc"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitter} alt="Twitter Logo" />
      </a>
      <a
        href="https://www.linkedin.com/company/brightride/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="LinkedIn Logo" className={styles.linkedin} />
      </a>
    </div>
    <div className={styles.legal}>
      <a
        href="https://app.termly.io/document/terms-of-use-for-saas/ea869917-2881-475a-b584-a02dbfeb9f84"
        target="_blank"
        rel="noreferrer"
      >
        Terms and Conditions
      </a>
      <a
        href="https://app.termly.io/document/privacy-policy/f532bf52-f11b-4380-bdc3-794cdc74a563"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      © {new Date().getFullYear()} Bright Ride, Inc. All rights reserved.
    </div>
  </footer>
);
