import React from 'react';
import { AreaChartData } from '../../models/area-chart';
import { runAreaChart } from '../../services/area-chart.service';

import styles from './area-chart.module.scss';


export interface BrAreaChartProps {
    data: AreaChartData[];
    config?: Record<string, any>;
}

export const BrAreaChart = ({
    data,
    config = {},
}: BrAreaChartProps) => {
    const containerRef = React.useRef(null);
    React.useEffect(() => {
        if (containerRef.current) {
            runAreaChart(containerRef.current, data, config);
        }

        return () => {};
    }, []);

    return <div
        className={styles.container}
        ref={containerRef}
    />;
}
