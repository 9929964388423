import React from "react";
import { AnalysisNavigation, ApplicationNavigation } from "./sidebar.config";

import styles from "./analysis-sidebar.module.scss";

export const BrAnalysisSidebar = () => {
    return <>
        <div className={styles.sideNav}>
            <nav role="Navigation" aria-label="Analysis Navigation">
                <ul>
                    {
                        AnalysisNavigation.map((item) =>
                            <li key={item.label}>
                                {item.label}
                            </li>
                        )
                    }
                </ul>
            </nav>
            <nav className={styles.secondaryNav} role="Navigation" aria-label="Application Navigation">
                <ul>
                    {
                        ApplicationNavigation.map((item) =>
                            <li key={item.label}>
                                {item.label}
                            </li>
                        )
                    }
                </ul>
            </nav>
        </div>
    </>;
};