// TODO: figure out types and remove ts-nocheck
// @ts-nocheck

import { Node, Vertex } from "../models/force-graph";
import * as d3 from "d3";

export function runForceGraph(
    container,
    {
        links,
        nodes,
    },
    {
        nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
        nodeGroup, // given d in nodes, returns an (ordinal) value for color
        nodeGroups, // an array of ordinal values representing the node groups
        nodeTitle, // given d in nodes, a title string
        nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
        nodeStroke = "#fff", // node stroke color
        nodeStrokeWidth = 1.5, // node stroke width, in pixels
        nodeStrokeOpacity = 1, // node stroke opacity
        nodeRadius = 5, // node radius, in pixels
        nodeStrength,
        linkSource = ({source}) => source, // given d in links, returns a node identifier string
        linkTarget = ({target}) => target, // given d in links, returns a node identifier string
        linkStroke = "#999", // link stroke color
        linkStrokeOpacity = 0.6, // link stroke opacity
        linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
        linkStrokeLinecap = "round", // link stroke linecap
        linkStrength,
        colors = d3.schemeTableau10, // an array of color strings, for the node groups
        width = 881, // outer width, in pixels
        height = 881, // outer height, in pixels
        invalidation // when this promise resolves, stop the simulation
    } = {},
) {
    let transform = d3.zoomIdentity;
    // Compute values.
    const N = d3.map(nodes, nodeId).map(intern);
    const LS = d3.map(links, linkSource).map(intern);
    const LT = d3.map(links, linkTarget).map(intern);
    if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];
    const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
    const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
    const W = typeof linkStrokeWidth !== "function" ? null : d3.map(links, linkStrokeWidth);
    const L = typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

    // Replace the input nodes and links with mutable objects for the simulation.
    nodes = d3.map(nodes, (_, i) => ({id: N[i]}));
    links = d3.map(links, (_, i) => ({source: LS[i], target: LT[i]}));

    // Compute default domains.
    if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

    // Construct the scales.
    const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

    // Construct the forces.
    const forceNode = d3.forceManyBody();
    const forceLink = d3.forceLink(links).id(({index: i}) => N[i]);
    if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
    if (linkStrength !== undefined) forceLink.strength(linkStrength);

    const simulation = d3.forceSimulation(nodes)
        .force("link", forceLink)
        .force("charge", forceNode)
        .force("center",  d3.forceCenter(width / 2, height / 2))
        .on("tick", ticked);

    d3.select(container).html(null);
    const svg = d3.select(container)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("style",`
            max-width: 100%;
            height: auto;
            height: intrinsic;
            clip-path: circle(50%);
        `);

    const defs = svg.append("defs");
    const gradient = defs.append("radialGradient")
        .attr("id", "radial-gradient");

    gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "white");
    gradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#EAF0F4")

    const bg = svg.append("g");
    bg.append("rect")
        .attr("fill", "url(#radial-gradient)")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height);

    const link = svg.append("g")
        .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
        .attr("stroke-opacity", linkStrokeOpacity)
        .attr("stroke-width", typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
        .attr("stroke-linecap", linkStrokeLinecap)
    .selectAll("line")
    .data(links)
    .join("line");

    const node = svg.append("g")
        .attr("fill", nodeFill)
        .attr("stroke", nodeStroke)
        .attr("stroke-opacity", nodeStrokeOpacity)
        .attr("stroke-width", nodeStrokeWidth)
        .selectAll("circle")
        .data(nodes)
        .join("circle")
            .attr("r", nodeRadius)
            // .call(drag(simulation));

    const circles = svg.append("g");
    const initialRadius = 76.5;
    new Array(12).fill(null).forEach((_, idx) =>
        circles.append("circle")
            .attr("cx", width / 2)
            .attr("cy", height / 2)
            .attr("r", (initialRadius * (idx ** 1.75)))
            .attr("stroke", "gray")
            .attr("fill", "rgba(0,0,0,0)")
    );

    if (W) link.attr("stroke-width", ({index: i}) => W[i]);
    if (L) link.attr("stroke", ({index: i}) => L[i]);
    if (G) node.attr("fill", ({index: i}) => color(G[i]));
    if (T) node.append("title").text(({index: i}) => T[i]);
    if (invalidation != null) invalidation.then(() => simulation.stop());

    function intern(value) {
        return value !== null && typeof value === "object" ? value.valueOf() : value;
    }

    function ticked() {
        link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);

        node
            .attr("cx", d => d.x)
            .attr("cy", d => d.y);
        }

        function drag(simulation) {
            function dragstarted(event) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                event.subject.fx = event.subject.x;
                event.subject.fy = event.subject.y;
              }

              function dragged(event) {
                event.subject.fx = event.x;
                event.subject.fy = event.y;
              }

              function dragended(event) {
                if (!event.active) simulation.alphaTarget(0);
                event.subject.fx = null;
                event.subject.fy = null;
              }

              return d3
                .drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended);
        }

    //ZOOM
    const zoomRect = svg
        .append("rect")
        .attr("width", width)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all");

    //ZOOM
    const zoom = d3
        .zoom()
        .scaleExtent([1 / 2, 64])
        .on("zoom", zoomed);

    //ZOOM
    zoomRect.call(zoom).call(zoom.translateTo, width / 2, height / 2);

    //ZOOM
    function zoomed(event) {
        transform = event.transform;
        node.attr("transform", event.transform);
        link.attr("transform", event.transform);
        circles.attr("transform", event.transform);
        // textElems.attr("transform", event.transform);
    }

    return svg.node();
}
