import React from "react";
import {ChevronDown} from "react-feather";

import { BrAnalysisGraph } from "../../../../components/analysis-graph/analysis-graph";
import { BrAreaChart } from "../../../../components/area-chart/area-chart";
import { BrButton } from "../../../../components/button/button";
import { BrTooltip } from "../../../../components/tooltip/tooltip";
import { AreaChartData } from "../../../../models/area-chart";
import { Node, Vertex } from "../../../../models/force-graph";

import styles from "./leadership-influence.module.scss";


export enum Connection {
    calendar = "Connect Calendar",
    generic = "Connect",
    video = "Connect Video Conferencing"
}

export interface LeadershipInfluenceMetrics {
    actions: { text: string; connections: Connection[] }[];
    activity: number;
    average: number;
    barriers: string[];
    centrality: "Low" | "Medium" | "High";
    companyConnections: number;
    orgSusceptibility: number;
    overall: {
        percentage: number;
        chartData: AreaChartData[];
    };
    teamConnections: number;
}

export interface BrLeaderShipInfluenceProps {
    links: Vertex[];
    nodes: Node[];
    metrics?: LeadershipInfluenceMetrics;
}
export const BrLeaderShipInfluence = ({
    links,
    metrics = {
        actions: [
            {
                text: "Use the employee voice solution to stay connected with everyone on your team despite local communication difficulty",
                connections: [Connection.generic]
            },
            {
                text: "Communicate goals to all members via periodical all company meetings: All-hands, QBR, Office hours, AMA.",
                connections: [Connection.calendar, Connection.generic],
            },
            {
                text: "Adjust your travel policy such that people see each other in person at least once a quarter and can make a few trips a year at their discretion.",
                connections: [],
            },
            {
                text: "Make all teams use the same tools, tech and, discussion forums to reduce communicaiton impedance.",
                connections: [],
            },
            {
                text: "To make signals propagate fast, you should build a small-world network. You can do it by making most active or effective people to have most connections. Make sure they have a time budget for other people.",
                connections: [],
            },
            {
                text: "Invest energy in every exchange: close physical proximity: gather in circles, fist bumps, hugs, profuse amount of eye contact. Provide support and presence.",
                connections: [],
            },
            {
                text: "Don't do long speeches, allow people to talk in short “notifications”, listen a lot, ask open ended questions.",
                connections: [],
            },
            {
                text: "Do not tolerate when things are unclear, especially when people around are uncomfortable.",
                connections: [],
            },
            {
                text: "Show that your relationship with each employee will continue, for example during the onboarding indicate that “we're together in this now.”",
                connections: [],
            },
        ],
        activity: 0,
        average: 0,
        barriers: [
            "John V. who mediates communication with 12 people is overloaded, high burnout risk",
            "Too many layers of management to Peter C.",
            "Sam's group is not susceptible",
            "Team with 12 people is at the different timezone and is loosely connected with leadership",
        ],
        centrality: "High",
        companyConnections: 0,
        orgSusceptibility: 0,
        overall: {
            chartData: [
                {
                    x: new Date("2022-01-15"),
                    y: 10,
                },
                {
                    x: new Date("2022-02-15"),
                    y: 2
                },
                {
                    x: new Date("2022-03-15"),
                    y: 15,
                },
                {
                    x: new Date("2022-04-15"),
                    y: 5
                },
                {
                    x: new Date("2022-05-15"),
                    y: 7,
                },
                {
                    x: new Date("2022-06-15"),
                    y: 20
                },
                {
                    x: new Date("2022-07-15"),
                    y: 2,
                },
                {
                    x: new Date("2022-08-15"),
                    y: 15
                },
                {
                    x: new Date("2022-09-15"),
                    y: 10,
                },
                {
                    x: new Date("2022-10-15"),
                    y: 8
                },
                {
                    x: new Date("2022-11-15"),
                    y: 6,
                },
                {
                    x: new Date("2022-12-15"),
                    y: 16
                },
            ],
            percentage: 0,
        },
        teamConnections: 0,
    },
    nodes,
}: BrLeaderShipInfluenceProps) => {
    return <>
        <div className={styles.container}>
            <h2>Leadership Influence</h2>
            <h3>Organization baseline metrics</h3>
            <div className={styles.section}>
                <div className={styles.overallMetricAndGraph}>
                    <div className={styles.overallInfluence}>
                        <p>Overall Leadership Influence: {metrics.overall.percentage}%</p>
                        <div><ChevronDown /></div>
                    </div>
                    <BrAreaChart
                        config={{width: 390, height: 90}}
                        data={metrics.overall.chartData}
                    />
                </div>
                <div>
                    <div className={styles.tableContainer}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Organization susceptibility</td>
                                    <td className={styles.redMetric}>{metrics.orgSusceptibility}%</td>
                                </tr>
                                <tr>
                                    <td>Leadership centrality</td>
                                    <td>{metrics.centrality}</td>
                                </tr>
                                <tr>
                                    <td>Leaders activity <br /><span>events/day</span></td>
                                    <td className={styles.redMetric}>{metrics.activity}</td>
                                </tr>
                                <tr>
                                    <td>Company average <br /><span>events/day</span></td>
                                    <td>{metrics.average}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>GRAPH HERE</div>
                    </div>
                    <div className={styles.underTable}>
                        <p className={styles.teamVsComp}>Connections inside the team vs. rest of the company</p>
                        <p>{metrics.teamConnections} vs {metrics.companyConnections}</p>
                    </div>
                </div>
            </div>
            <h3>Leadership influence reach chart</h3>
            <div className={styles.influence}>
                <div>
                    <p>Spreading Barriers</p>
                    <ul>
                        {
                            metrics.barriers.map(barrier =>
                                <li key={barrier}>{barrier}</li>
                            )
                        }
                    </ul>
                </div>
                <BrAnalysisGraph
                    links={links}
                    nodes={nodes}
                    onNodeGroupToggle={() => console.log('hello toggle')}/>
            </div>
            <div>
                <h3>Top Level Actions</h3>
                <ul className={styles.actions}>
                    {
                        metrics.actions.map(action =>
                            <li key={action.text}>
                                <div>
                                    <input type="checkbox" />
                                    <span>{action.text}</span>
                                </div>
                                <div className={styles.actionBtns}>
                                    {
                                        action.connections.map(connection =>
                                            <BrButton key={connection}>{connection}</BrButton>
                                        )
                                    }
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    </>;
};
