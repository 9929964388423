import { BrFooter } from "../footer/footer";
import { BrHeader } from "../header/header";

export const Layout = ({children}: any) => {
    return <>
        <BrHeader />
        <main className="app-view">
            {children}
        </main>
        <BrFooter />
    </>;
}