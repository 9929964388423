import { redirect } from "react-router-dom";
import { SIGN_IN } from "../../constants/routes";
import { getConnections } from "../../api/connections/get-connections";
import { companyStore, CompanyStoreKey } from "../../store/company.store";
import { userStore, UserStoreKey } from "../../store/user.store";
import { Connection } from "../../models/connection";

export default async function dataSourceLoader({request}: any) {
    if (userStore.getData(UserStoreKey.ID_TOKEN)) {
        const connectionResponse = await getConnections(companyStore.getData(CompanyStoreKey.COMPANY_ID));

        if (connectionResponse.ok) {
            try {
                const connections: Connection[] = await connectionResponse.json();

                return new Map(connections.map(({integrationId, id}) => [integrationId, [id]]));
            } catch (err) {
                // TODO: error handling
                console.log(err);

                return [];
            }
        }
    } else {
        return redirect("/" + SIGN_IN);
    }
}