import React from 'react';
import { BrAnalysisGraph } from '../../components/analysis-graph/analysis-graph';
import { BrAnalysisSidebar } from '../../components/analysis-sidebar/analysis-sidebar';

import styles from "./analysis.module.scss";
import { BrLeaderShipInfluence } from './components/leadership-influence/leadership-influence';

const data = {
    "nodes": [
      {
        "id": 1,
        "name": "Andy",
        "gender": "male"
      },
      {
        "id": 2,
        "name": "Betty",
        "gender": "female"
      },
      {
        "id": 3,
        "name": "Cate",
        "gender": "female"
      },
      {
        "id": 4,
        "name": "Dave",
        "gender": "male"
      },
      {
        "id": 5,
        "name": "Ellen",
        "gender": "female"
      },
      {
        "id": 6,
        "name": "Fiona",
        "gender": "female"
      },
      {
        "id": 7,
        "name": "Garry",
        "gender": "male"
      },
      {
        "id": 8,
        "name": "Holly",
        "gender": "female"
      },
      {
        "id": 9,
        "name": "Iris",
        "gender": "female"
      },
      {
        "id": 10,
        "name": "Jane",
        "gender": "female"
      }
    ],
    "links": [
      {
        "source": 1,
        "target": 2
      },
      {
        "source": 1,
        "target": 5
      },
      {
        "source": 1,
        "target": 6
      },

      {
        "source": 2,
        "target": 3
      },
      {
        "source": 2,
        "target": 7
      }
    ,

      {
        "source": 3,
        "target": 4
      },
      {
        "source": 8,
        "target": 3
      }
    ,
      {
        "source": 4,
        "target": 5
      }
    ,

      {
        "source": 4,
        "target": 9
      },
      {
        "source": 5,
        "target": 10
      }
    ]
  };

export const Analysis = () => {
    return <>
        <div className={styles.container}>
            <BrAnalysisSidebar />
            <BrLeaderShipInfluence
                links={data.links}
                nodes={data.nodes} />
        </div>
    </>;
};
