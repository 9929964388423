
// import workday from '../../assets/workday.png';
import atlassian from '../../assets/atlassian.png';
import salesforce from "../../assets/salesforce.png";
import hubspot from "../../assets/hubspot.png";
import { DataSource } from '../../models/data-source';

export const upcomingSources: DataSource[] = [
    // {
    //     img: workday,
    //     caption: "nterprise Management for finance, HR, planning, and spend management.",
    //     title: "Workday",
    // },
    {
        connectionIds: [],
        img: salesforce,
        integrationId: "SalesForce",
        caption: "Personalize every experience along the customer journer with the Customer 360. Unify marketing, sales, service, commerce, and IT on the world's #1 CRM",
        title: "SalesForce",
        windowDimensions: {
            height: 0,
            width: 0,
        },
    },
    {
        connectionIds: [],
        img: hubspot,
        integrationId: "HubSpot",
        caption: "HubSpot's integrated CRM platform contains the marketing, sales, service, operations, and website-building software you need to grow your business",
        title: "HubSpot",
        windowDimensions: {
            height: 0,
            width: 0,
        },
    },
    {
        connectionIds: [],
        img: atlassian,
        integrationId: "Confluence",
        caption: "Confluence is a collaborative platform that enables teams to create, organize, and share content such as documents, ideas, and project plans in a centralized digital space.",
        title: "Confluence",
        windowDimensions: {
            height: 0,
            width: 0,
        },
    },
];