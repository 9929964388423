import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import {
  ANALYSIS,
  CONNECT,
  FORGOT,
  ROOT,
  SIGN_IN,
  SIGN_UP,
} from "./constants/routes";
import { AuthForms, VisibleForm } from "./pages/auth-forms/auth-forms";
import { BrConnectDataSources } from "./pages/connect-data-sources/connect-data-sources";
import { Analysis } from "./pages/analysis/analysis";
import "./App.scss";
import { Layout } from "./components/layout/layout";
import dataSourceLoader from "./utils/loaders/data-source-loader";
import rootLoader from "./utils/loaders/root-loader";

const router = createBrowserRouter([
  {
    path: ROOT,
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    loader: rootLoader,
    children: [
      {
        path: "/",
        loader: () => redirect("/" + ANALYSIS),
      },
      {
        path: SIGN_IN,
        element: <AuthForms visibleForm={VisibleForm.SIGN_IN} />,
      },
      {
        path: SIGN_UP,
        element: <AuthForms visibleForm={VisibleForm.SIGN_UP} />,
      },
      {
        path: FORGOT,
        element: <AuthForms visibleForm={VisibleForm.FORGOT} />,
      },
      {
        path: CONNECT,
        element: <BrConnectDataSources />,
        loader: dataSourceLoader,
        children: [
          {
            path: ":connector",
            element: <BrConnectDataSources />,
          },
        ],
      },
      {
        path: ANALYSIS,
        element: <Analysis />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

function Router() {
  return <RouterProvider router={router} />;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
