import { NavigateFunction } from "react-router-dom";
import { HEADERS } from "../constants/api";
import { CONNECT } from "../constants/routes";
import { getCompanies } from "../api/company/get-companies";
import { userStore, UserStoreKey } from "../store/user.store";
import { companyStore, CompanyStoreKey } from "../store/company.store";

export default function signInSuccess(data: any, nav: NavigateFunction) {
    userStore.setData(UserStoreKey.ID_TOKEN, data.idToken);
    userStore.setData(UserStoreKey.REFRESH_TOKEN, data.refreshToken);
    HEADERS.set("Authorization", data.idToken);

    getCompanies()
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            throw new Error(`${response.status}: ${response.statusText}`)
        })
        .then((data) => {
            companyStore.setData(CompanyStoreKey.COMPANY_ID, data[0].id);
            nav("/" + CONNECT);
        })
        .catch((err) => {
            console.error(err);
            // TODO: error handling
        });
}
