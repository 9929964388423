export const AnalysisNavigation = [
    {
        label: "Vision and goals",
    },
    {
        label: "Groundedness",
    },
    {
        label: "Trending Topics",
    },
    {
        label: "Customer Relations",
    },
];

export const ApplicationNavigation = [
    {
        label: "Data Sources",
        link: "/data-sources",
    },
    {
        label: "Preferences",
    },
    {
        label: "Logout",
    },
];
