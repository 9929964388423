import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { HttpMethod } from "../../models/http-methods";
import { httpService } from "../../services/http.service";
import { CompanyStoreKey, companyStore } from "../../store/company.store";
import { UserStoreKey, userStore } from "../../store/user.store";

export const deleteConnection = (connectionId: string) => {
    const companyId = companyStore.getData(CompanyStoreKey.COMPANY_ID);
    HEADERS.set("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));

    return httpService.fetch(`${API_BASE}${API_VERSION}/companies/${companyId}/connections/${connectionId}`, {
        headers: HEADERS,
        method: HttpMethod.DELETE,
        mode: CORS,
    });
}

// 200	
// Successful operation

// 400	
// Invalid request data

// 404	
// Integration connection is not found

// Example Value
// Model
// {
//   "message": "string"
// }
