import slack from '../../assets/slack.png';
import google from '../../assets/google-workspace.png';
import atlassian from '../../assets/atlassian.png';
import github from '../../assets/github.png';
import orgChart from "../../assets/org-chart.svg";
// import microsoft from '../../assets/microsoft-teams.png';
import { DataSource } from '../../models/data-source';

export const FILE_TITLE = "Org Chart CSV file";
export const UPCOMING_SOURCES_TITLE = "Upcoming data sources";

export const dataSources: DataSource[] = [
    {
        connectionIds: [],
        img: slack,
        integrationId: "Slack",
        caption: 'Platform for teams and businesses of all sizes to communicate effectively',
        title: 'Slack',
        windowDimensions: {
            height: 534,
            width: 683,
        },
    },
    {
        connectionIds: [],
        img: google,
        integrationId: "GoogleWorkspace",
        caption: 'Google Worspace\'s (formerly G Suite) secure collaboration and productivity apps for businesses of all sizes. Including Gmail, Drive, Meet and more',
        title: 'Google Workspace',
        windowDimensions: {
            height: 634,
            width: 683,
        },
    },
    {
        connectionIds: [],
        img: github,
        integrationId: "GitHub",
        caption: 'The complete developer platform to build, scale, and deliver secure software',
        title: 'Github',
        windowDimensions: {
            height: 800,
            width: 683,
        },
    },
    {
        connectionIds: [],
        img: atlassian,
        integrationId: "Jira",
        caption: 'Provides collaboration, development, and issue tracking software for teams',
        title: 'Jira',
        windowDimensions: {
            height: 650,
            width: 683,
        },
    },
    // {
    //     img: microsoft,
    //     caption: 'Chat, collaborate, and meet from anywhere in just one place with MS teams',
    //     title: 'Microsoft Teams',
    // }
    {
        connectionIds: [],
        caption: "File should contain columns Name, Employee ID, Title, Email, Manager Name, Manager ID",
        img: orgChart,
        title: FILE_TITLE,
        integrationId: "",
        windowDimensions: {
            height: 0,
            width: 0,
        }
    },
];