// TODO: figure out types and remove ts-nocheck
// @ts-nocheck

import { AreaChartData } from "../models/area-chart";
import * as d3 from "d3";
import { MONTH } from "../models/month";

export const runAreaChart = (
    container: HTMLElement,
    data: AreaChartData[], {
    x = ({x}) => x, // given d in data, returns the (temporal) x-value
    y = ({y}) => y, // given d in data, returns the (quantitative) y-value
    defined, // given d in data, returns true if defined (for gaps)
    curve = d3.curveLinear, // method of interpolation between points
    marginTop = 0, // top margin, in pixels
    marginRight = 20, // right margin, in pixels
    marginBottom = 20, // bottom margin, in pixels
    marginLeft = 0, // left margin, in pixels
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    xType = d3.scaleUtc, // type of x-scale
    xDomain, // [xmin, xmax]
    xRange = [marginLeft, width - marginRight], // [left, right]
    yType = d3.scaleLinear, // type of y-scale
    yDomain, // [ymin, ymax]
    yRange = [height - marginBottom, marginTop], // [bottom, top]
    yFormat, // a format specifier string for the y-axis
    yLabel, // a label for the y-axis
    color = "#FFE49F" // fill color of area
    }: Record<string, any> = {}) => {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const I = d3.range(X.length);
    // Compute which data points are considered defined.
    if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(data, defined);

    // Compute default domains.
    if (xDomain === undefined) xDomain = d3.extent(X);
    if (yDomain === undefined) yDomain = [0, d3.max(Y)];

    // Construct scales and axes.
    const xScale = xType(xDomain, xRange);
    const yScale = yType(yDomain, yRange);
    const xAxis = d3.axisBottom(xScale).ticks(X.length).tickSizeOuter(0).tickFormat((date: Date) =>
        `${MONTH[date.getMonth()]} ${date.getDay() + 1}`
    );
    const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);

    // Construct an area generator.
    const area = d3.area()
        .defined(i => D[i])
        .curve(curve)
        .x(i => xScale(X[i]))
        .y0(yScale(0))
        .y1(i => yScale(Y[i]));

     d3.select(container).html(null);
    const svg = d3.select(container)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    const defs = svg.append("defs");
    const gradient = defs.append("linearGradient")
        .attr("id", "linear-gradient");

    gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "white");
    gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", color)


    svg.append("path")
        .attr("fill", color)
        .attr("d", area(I));

    svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .attr("class", "x-axis")
        .call(xAxis)

    svg.selectAll(".domain")
        .attr("stroke", "transparent")

    svg.selectAll(".x-axis .tick")
        .append("rect")
            .attr("width", 40)
            .attr("height", height)
            .attr("x", -20)
            .attr("y", -100)
            .style("pointer-events", "all")
            .style("visibility", "hidden")
            .on("mouseover", ({target}, d) => {
                d3.select(target.parentNode)
                    .select("line")
                    .attr("stroke", "black");

                d3.select(target.parentNode)
                    .select("text")
                    .attr("fill", "black");
            })
            .on("mouseout", ({target}) => {
                d3.select(target.parentNode)
                    .select("line")
                    .attr("stroke", "transparent");
                d3.select(target.parentNode)
                    .select("text")
                    .attr("fill", "transparent");
            });
    svg.selectAll(".x-axis .tick line")
        .attr("y1", -15)
        .attr("y2", -100)
        .attr("stroke", "transparent")

    svg.selectAll(".x-axis .tick text")
        .attr("y", -10)
        .attr("fill", "transparent")

    return svg.node();
};
