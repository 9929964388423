import { useEffect, useState } from "react";
import { dataSources, UPCOMING_SOURCES_TITLE } from "./data-sources";

import styles from "./connect-data-sources.module.scss";
import connectionBar from "../../assets/brightride-connection-bar.png";
import { crmSources } from "./crm-sources";
import { useLoaderData, useLocation, useParams } from "react-router-dom";
import { upcomingSources } from "./upcoming-sources";
import { DataSource } from "../../models/data-source";
import { initiateCreateConnection } from "../../api/connections/initiate-create-connection";
import { confirmCreateConnection } from "../../api/connections/confirm-create-connection";
import { runOnce } from "../../utils/run-once";
import { ConnectionId, IntegrationId } from "../../models/connection";
import { ORIGIN } from "../../constants/env";
import { SourceButton } from "./source-button/source-button";
import { BrLoadingBar } from "../../components/loading-bar/loading-bar";

let authWindow: Window | null;

const sources: { title: string; sources: DataSource[] }[] = [
  {
    title: "Supported data sources",
    sources: dataSources,
  },
  {
    title: "CRM systems",
    sources: crmSources,
  },
  {
    title: UPCOMING_SOURCES_TITLE,
    sources: upcomingSources,
  },
];

export const BrConnectDataSources = () => {
  const connections = useLoaderData() as Map<IntegrationId, ConnectionId[]>;
  const { connector } = useParams();
  const location = useLocation();
  const [cachedConnections, setCachedConnections] =
    useState<Map<IntegrationId, ConnectionId[]>>(connections);
  const [hasButtonBeenClicked, setHasButtonBeenClicked] = useState(false);

  const messageHandler = (event: MessageEvent<string>) => {
    if (event.origin !== ORIGIN) return;
    authWindow?.close();
    const query = event.data;

    confirmCreateConnection(query)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error(`${response.status}: ${response.statusText}`);
      })
      .then((connection) => {
        const currentConnections =
          cachedConnections.get(connection.integrationId) ??
          ([] as ConnectionId[]);
        cachedConnections.set(
          connection.integrationId,
          currentConnections.concat(connection.id)
        );
        setHasButtonBeenClicked(true);

        setCachedConnections(new Map(cachedConnections));
      })
      .catch((err) => {
        console.error(err);

        // TODO: Error Handling
      });
  };

  useEffect(() => {
    if (connector) {
      window.opener?.postMessage(location.search, "*");
    } else {
      runOnce(() => {
        window.addEventListener("message", messageHandler, false);
      })();
    }

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h2>Connect data sources</h2>
        {sources
          .filter((category) => category.sources.length)
          .map((category) => (
            <div key={category.title}>
              <p className={styles.supported}>{category.title}</p>
              <ul>
                {category.sources.map((source) => (
                  <li key={source.title}>
                    <div className={styles.source}>
                      <img src={source.img} alt="source" />
                      <div className={styles.sourceText}>
                        <h3>{source.title}</h3>
                        <p>{source.caption}</p>
                      </div>
                      <SourceButton
                        cachedConnections={cachedConnections}
                        categoryTitle={category.title}
                        createConnection={createConnection}
                        integrationId={source.integrationId}
                        setCachedConnections={setCachedConnections}
                        title={source.title}
                        windowDimensions={source.windowDimensions}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
      <div className={styles.right}>
        <h2>
          Please connect your communication and collaboration services your
          company is using.
        </h2>
        <h2>
          The more services you add, more thoroughly we can reconstruct your org
          communication network and provide better recommendations.
        </h2>
        {cachedConnections.size > 0 && (
          <>
            <p>
              Data processing is in progress. Usually, it takes a few minutes,
              so please feel free to log out and come back later.
            </p>
            <BrLoadingBar />
          </>
        )}
      </div>
    </div>
  );

  async function createConnection(
    integrationId: IntegrationId,
    { height, width }: DataSource["windowDimensions"]
  ) {
    const response = await initiateCreateConnection(integrationId);
    if (response.ok) {
      const url = await response.json();

      authWindow = window.open(
        url.value,
        "",
        `popup, width=${width}, height=${height}, top=${
          (window.screen.height - height) / 4
        }, left=${(window.screen.width - width) / 2}`
      );
    } else {
      // TODO: Error Handling
    }
  }
};
