import React, { MouseEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { BrInput } from '../../../../components/input/input';

import styles from '../../auth-forms.module.scss';
import { login } from '../../../../api/auth/login';
import extractValuesFromForm from '../../../../utils/extract-values-from-form';
import { LoginType } from '../../../../models/login-type';
import { FormFooter } from '../form-footer/form-footer';
import { VisibleForm } from '../../auth-forms';


export const BrLogin = () => {
    const navigate = useNavigate();
    const formRef = useRef(null);

    return <div className={styles.formContainer}>
        <form ref={formRef}>
            <BrInput label="Your Email" name="login" />
            <div className={styles.divider} />
            <BrInput type="password" label="Password" name="password" />
            <FormFooter submit={formSubmit} visibleForm={VisibleForm.SIGN_IN}/>
            <input type="hidden" name="type" value={LoginType.password} />
        </form>
    </div>;

    function formSubmit(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        login(extractValuesFromForm(formRef.current!), navigate);
    }
}
