export class Store<T extends string> {
    protected store: Record<T, any> = {} as Record<T, any>;

    getData(key: T) {
        return this.store[key];
    }

    setData(key: T, value: any) {
        this.store[key] = value;
    }
}