import { FORGOT, SIGN_IN, SIGN_UP } from "../../../../constants/routes";

export const signUp = {
    question: "Don't have an account?",
    link: SIGN_UP,
    cta: "Sign up"
};

export const forgot = {
    question: "Forgot password?",
    link: FORGOT,
    cta: "Click here"
};

export const signIn = {
    question: "Already have an account?",
    link: SIGN_IN,
    cta: "Sign In",
};