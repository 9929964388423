import React from "react";
import { BrLogin } from "./components/login/login";
import { BrRegister } from "./components/register/register";
import signIn from "../../assets/hero.jpeg";

import styles from "./auth-forms.module.scss";
import { Forgot } from "./components/forgot/forgot";

export enum VisibleForm {
  SIGN_IN,
  SIGN_UP,
  FORGOT,
}

export interface AuthFormsProps {
  visibleForm: VisibleForm;
}

export const AuthForms = ({ visibleForm }: AuthFormsProps) => (
  <div className={styles.container}>
    <div className={styles.forms}>
      <h2>Sign into your Bright Ride</h2>
      <h3>rally the team, deliver what is important to everyone</h3>
      {visibleForm === VisibleForm.SIGN_IN && <BrLogin />}
      {visibleForm === VisibleForm.SIGN_UP && <BrRegister />}
      {visibleForm === VisibleForm.FORGOT && <Forgot />}
    </div>
    <div className={styles.hero}>
      <img src={signIn} alt="computer workstation" />
    </div>
  </div>
);
