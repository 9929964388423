import { API_BASE, API_VERSION, CORS } from "../../constants/api"
import { HttpMethod } from "../../models/http-methods"
import { CompanyStoreKey, companyStore } from "../../store/company.store";
import { UserStoreKey, userStore } from "../../store/user.store";

export const initiateCreateConnection = (
    integrationId: string,
) => {
    const headers = new Headers();
    headers.append("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));
    headers.append("Content-Type", "application/json");
    const companyId = companyStore.getData(CompanyStoreKey.COMPANY_ID);

    return fetch(`${API_BASE}${API_VERSION}/companies/${companyId}/connections`, {
        headers,
        method: HttpMethod.POST,
        mode: CORS,
        body: JSON.stringify({
            companyId,
            integrationId,
        }),
    });
}

// 200	
// Successful operation

// Example Value
// Model
// {
//   "value": "string"
// }
// 400	
// Invalid request data
