export const runOnce = (fn: () => void) => {
    let hasRan = false;

    return () => {
        if (!hasRan) {
            hasRan = true;
            fn();
        }
    };
};
