import { Link } from "react-router-dom";

import styles from "./header.module.scss";
import triangle from "../../assets/top-triangle.svg";
import br from "../../assets/header-logo.jpg";

export const BrHeader = () => (
  <header className={styles.header}>
    <img src={triangle} className={styles.triangle} alt="triangle" />
    <h1>
      <Link to="/">
        <img className={styles.logo} src={br} alt="BrightRide" />
      </Link>
    </h1>
  </header>
);
