import { API_BASE, API_VERSION, CORS, HEADERS } from "../../constants/api";
import { DocumentType } from "../../models/document-type";
import { HttpMethod } from "../../models/http-methods";
import { httpService } from "../../services/http.service";
import { userStore, UserStoreKey } from "../../store/user.store";

export const uploadCompanyDocument = (
    companyId: string,
    document: File,
    documentType: DocumentType = DocumentType.other
) => {
    const tmpHeaders = new Headers();

    for (const [hKey, hVal] of HEADERS.entries()) {
        tmpHeaders.set(hKey, hVal);
    }
    tmpHeaders.set("Authorization", userStore.getData(UserStoreKey.ID_TOKEN));
    tmpHeaders.delete("Content-Type");

    const fd = new FormData();
    fd.append("document", document);

    return httpService.fetch(`${API_BASE}${API_VERSION}/companies/${companyId}/documents?document-type=${documentType}`, {
        body: fd,
        headers: tmpHeaders,
        method: HttpMethod.POST,
        mode: CORS,
    });
};

// 200	
// Successfully uploaded the document

// Example Value
// Model
// {
//   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "name": "string",
//   "companyId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "documentType": "OrgChart"
// }
// 400	
// Invalid request data
