import { MouseEvent, useMemo } from "react";
import { BrButton } from "../../../../components/button/button";
import { Link } from "react-router-dom";

import styles from "./form-footer.module.scss";
import { VisibleForm } from "../../auth-forms";
import { forgot, signIn, signUp } from "./form-footer.config";

export interface FormFooterProps {
  submit: (e: MouseEvent<HTMLButtonElement>) => void;
  visibleForm: VisibleForm;
}

export const FormFooter = ({ submit, visibleForm }: FormFooterProps) => {
  const config = useMemo(() => {
    switch (visibleForm) {
      case VisibleForm.SIGN_IN:
        return [signUp, forgot];
      case VisibleForm.SIGN_UP:
        return [signIn, forgot];
      case VisibleForm.FORGOT:
        return [signIn, signUp];
    }
  }, [visibleForm]);

  return (
    <div className={styles.formFooter}>
      <div>
        {config.map((c) => (
          <p key={c.link}>
            {c.question}{" "}
            <Link className={styles.formLink} to={`/${c.link}`}>
              {c.cta}
            </Link>
          </p>
        ))}
      </div>
      <div className={styles.submitContainer}>
        <BrButton onClick={submit}>
          {visibleForm === VisibleForm.FORGOT ? "Send Reset Email" : "Sign in"}
        </BrButton>
      </div>
    </div>
  );
};
